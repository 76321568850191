<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else class="content-reward">
      <HistoryRewardDetail :detail="privilegeDetail" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import HistoryRewardDetail from "@/components/reward/detail/HistoryRewardDetail";
export default {
  components: {
    Breadcrumb,
    HistoryRewardDetail,
    OtherLoading
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "ประวัติการแลกของรางวัล",
          to: "/reward?tabIndex=1"
        }
      ],
      privilegeDetail: {},
      id: this.$route.params.id,
      isLoading: true
    };
  },
  async created() {
    await this.getRedeemInfo();
  },
  methods: {
    async getRedeemInfo() {
      let type = this.$route.query.type;
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/redeem/detail/${this.id}/${type}`
        )
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.privilegeDetail = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    }
  }
};
</script>

<style lang="scss" scoped></style>
